import React,{useState} from "react"
import Arrow from "../../../../assets/svg/down_arrow_icon.svg"
import Img1 from "../../../../images/new/check-box-d.svg"
import Img2 from "../../../../images/new/check-box-s.svg"
import { Input } from 'antd';

import "./wash.css"
const FinalInspection=()=>{
    const { TextArea } = Input;
    const [shows, setShows] = useState(false)
    const [longs, setLongs] = useState(100)
    const [openInput, setOpenInput] = useState([])
  const imageChange = id => {
    const isAlreadyOpen = openInput?.includes(id)
    if (isAlreadyOpen) {
      const filterdItem = openInput?.filter(item => item !== id)
      setOpenInput(filterdItem)
    } else {
      setOpenInput([...openInput, id])
    }
  }
    const accordionHandlers = item => {
        setLongs(item.id)
        item.id === longs ? setShows(!shows) : setShows(true)
      }
      const changeServices=[
        {id:1,name:"Compare Car Notes and Confirm Repairs Performed, If applicable"},
        {id:2,name:"Gaskets Cleaned"},
        {id:3,name:"Pellets Clearded from Gate Pan, Flow Tubes, Internal Mechanisms"},
        {id:4,name:"Hatch Covers and Screens Cleaned"},
        {id:5,name:"Outlet Caps, Nozzles and Gaskets Cleaned"},
        {id:6,name:"Ceilings, Walls, Slopes and Separation Sheets Cleaned"},
        {id:7,name:"Bottom Outlets and Flow Tubes Rinsed"},
        {id:8,name:"Car Dry"},
        {id:9,name:"Gate Tubes Swabbed"},
        {id:10,name:"Outlet Caps and Gaskets Wiped Dry"},
        {id:11,name:"Hatches/Combings/Gaskets Wiped Down"},
        {id:12,name:"Vented Hatch Cover and Screens Assembled and Applied"},
        {id:13,name:"Gates Reapplied and Torqued to Specification"},
        {id:14,name:"Confirm Photo Package is Complete"},
        {id:15,name:"Seals Applied"},
    ]
      const data = [
        {
          id: 100,
          title: "Hopper Car Change of Service",
          description: <div className="change_service_checkbx">
                         <div className="change_service_checkbx_container">
                            {changeServices?.map((item,index)=>{
                            return <div key={index} className="change_service_dt">
                                <div>{item.name}</div>
                                <img src={openInput?.includes(item?.id) ? Img2 : Img1} alt="check images"
                                 onClick={() => imageChange(item.id)}/>
                            </div>
                         })}
                         <p>Note</p>
                         <TextArea rows={4} placeholder="Write something here..." maxLength={6} />
                         </div>
                        </div>,
        },
        {
          id: 101,
          title: "Hopper Car Rinse Out / Return To Service Inspection",
          description: 'nop data'
        },
        {
            id: 102,
            title: "Tank Car Final Inspection",
            description: 'no data'
          },
          {
            id: 103,
            title: "No Cleaning Repair Only Car Inspection",
            description: 'no data'
          },
      ]
    return (
        <>
          <div className='ticket_details_accordionyrd' style={{marginTop:"-10px"}}>
          <div className='contact_sectionyrd'>
          <div className='contact_containeryrd'>
            {data.map((item, index) => {
              return (
                <>
                  <div
                  style={{boxShadow:shows && item.id === longs?'none':"",background:shows && item.id === longs?"":"#fff",}}
                    key={index}
                    className={
                      shows && item.id === longs
                        ? 'contact_accordion_activeyrd'
                        : 'contact_accordionyrd'
                    }
                  >
                    <div
                      className='contact_accordion_headyrd'
                      onClick={() => accordionHandlers(item)}
                      style={{
                        background:"#fff",
                        padding:"0px 0px",
                        height: shows && item.id === longs ? "7vh" : "10vh",
                        borderBottom:
                           "1px solid #00000029",
                      }}
                    >
                      <h3 style={{color:"#274576"}}>{item.title}</h3>
                      <img
                        src={Arrow}
                        alt="icon forword"
                        style={{
                          transform:
                            shows && item.id === longs ? "rotate(180deg)" : "",
                        }}
                      />
                    </div>
                    <div
                      className={
                        shows && item.id === longs
                          ? 'contact_accordion_desc_activeyrd'
                          : 'contact_accordion_descyrd'
                      }
                    >
                      {item.description}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
          </div>
        </>
    )
}
export default FinalInspection;